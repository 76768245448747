<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <h1>DIVISA</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="8">
                <div v-if="loading">Cargando</div>
                <div v-else>
                    <v-expansion-panels>
                        <v-expansion-panel v-for="(divisa, index) of list" :key="`divisa-list-${divisa.id}-${index}`">
                            <v-expansion-panel-header>
                                <div class="d-flex justify-space-between">
                                    <div>
                                        {{ divisa.name }}
                                    </div>
                                    <div class="flex-1" v-if="divisaDefault">
                                        {{ divisa.rate | currency(divisaDefault.code) }}
                                    </div>
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <Rate :divisa="divisa" :divisaDefault="divisaDefault" @saved="getList()"/>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>
            </v-col>
            <v-col cols="12" sm="4">
                <v-card>
                    <v-card-title>
                        <span class="text-center" style="width: 100%">
                            Divisa Por Defecto
                        </span>
                    </v-card-title>
                    <v-card-text class="text-center">
                        <h2 v-if="divisaLoading">Cargando</h2>
                        <template v-else-if="divisaDefault">
                            <h2>{{ divisaDefault.name }}</h2>
                            <p class="ma-0">Code: <b>{{ divisaDefault.code }}</b> Symbol: <b>{{ divisaDefault.symbol }}</b></p>
                        </template>
                        <h2 v-else>Error cargando</h2>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import DivisaService from '@/services/general/DivisaService';

import Rate from '@components/entities/general/divisa/rate';

export default {
    components: {
        Rate
    },
    data: () => ({
        loading: false,
        list: [],
        divisaLoading: false,
        divisaDefault: null,
    }),
    methods: {
        getList: function () {
            this.loading = true;
            this.list = [];
            DivisaService
                .getList({is_default: false})
                .then(({ divisaList }) => {
                    this.list = divisaList;
                })
                .finally(() => this.loading = false);
        },
        get: function () {
            this.divisaDefaultLoading = true;
            DivisaService
                .getBy({is_default: true, is_active: true})
                .then(({ divisa }) => {
                    this.divisaDefault = divisa;
                })
                .finally(() => this.divisaDefaultLoading = false);
        },
    },
    mounted: function () {
        this.$store.dispatch('ui/layout/setTitle', 'Edición de Divisas');
        this.getList();
        this.get();
    }
}
</script>