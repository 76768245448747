import Vue from 'vue'

import './registerServiceWorker'

Vue.config.productionTip = false

Vue.component('TimeInput', () => import('@components/generic/time-input'));
Vue.component('DateRangeInput', () => import('@components/generic/daterange-input'));
Vue.component('DateInput', () => import('@components/generic/date-input'));
Vue.component('PercentInput', () => import('@components/generic/percent-input'));
Vue.component('LazySelector', () => import('@components/generic/lazy-selector'));
Vue.component('CurrencyInput', () => import('@components/generic/currency-input'));
Vue.component('PasswordInput', () => import('@components/generic/password-input'));

Vue.component('SelectorNumero', () => import('@components/generic/selectors/numero'));
Vue.component('SelectorJuego', () => import('@components/generic/selectors/juego'));
Vue.component('SelectorJuegoSorteo', () => import('@components/generic/selectors/juego-sorteo'));
Vue.component('SelectorDivisa', () => import('@components/generic/selectors/divisa'));
Vue.component('SelectorDivisaChip', () => import('@components/generic/selectors/divisa-chip'));
Vue.component('SelectorSistema', () => import('@components/generic/selectors/sistema'));
Vue.component('SelectorSistemaType', () => import('@components/generic/selectors/sistema-type'));

import App from './App.vue'

import store from './stores'
import router from './router'
import i18n from './i18n'
import vuetify from './plugins/vuetify'

import luxon, { $luxon } from './plugins/luxon';
import ui from './plugins/ui';
import user from './plugins/user';
import lodash from './plugins/lodash';
import config from './plugins/config';
import helper from './plugins/helper';

Vue.use(luxon);
Vue.use(ui);
Vue.use(user);
Vue.use(lodash);
Vue.use(helper);

import VCalendar from 'v-calendar';

Vue.use(VCalendar, {
    componentPrefix: 'vc',
});

Vue.filter('currency', function (value, code = null) {
  return store.getters['persist/divisa/format'](value, code);
});

Vue.filter('currencyDefault', function (value, code = null) {
  return store.getters['persist/divisa/formatDefault'](value, code);
});

const percentFormatter = new Intl.NumberFormat(config.get('COUNTRY_CODE'), { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 });

Vue.filter('percent', function (value) {
  return percentFormatter.format(value);
});

Vue.filter('date', function (value) {
  if ($luxon.isDateTime(value)) return value.toLocaleString($luxon.DATE_SHORT);
  return $luxon.fromISO(value).toLocaleString($luxon.DATE_SHORT);
});

Vue.filter('datetime', function (value) {
  if ($luxon.isDateTime(value)) return value.toLocaleString($luxon.DATETIME_SHORT_WITH_SECONDS);
  return $luxon.fromISO(value).toLocaleString($luxon.DATETIME_SHORT_WITH_SECONDS);
});

Vue.filter('bdatetime', function (value) {
  return $luxon.fromSQL(value).toLocaleString($luxon.DATETIME_SHORT);
});

window.Vue = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
  mounted: function () {
    store.dispatch('ui/initTimer');
    store.dispatch('persist/divisa/setDefault');
  }
}).$mount('#app')