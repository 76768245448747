<template>
    <div class="d-inline">
        <v-btn v-if="cupoSistema" text small @click="dialog = !dialog"><v-icon small>mdi-pencil</v-icon> EDITAR</v-btn>
        <v-btn v-else text small @click="dialog = !dialog">AÑADIR</v-btn>
        <v-dialog v-model="dialog" width="600">
            <v-card>
                <v-card-title>
                    <h3>CupoSistema</h3>
                </v-card-title>
                <v-card-text class="pa-3">
                    <v-container fluid>
                        <template v-if="context == 'new'">
                            <CupoSistemaForm :loading="loading" v-model="cupoSistemaData" :default="defaultCupoData" :valid.sync="isCupoSistemaDataValid" :sistema="sistema"/>
                        </template>
                        <template v-if="cupoSistemaData || context == 'edit'">
                            <CupoForm :loading="loading" v-model="cupoData" :default="defaultCupoData" :valid.sync="isCupoDataValid" :juego_id="cupoSistemaData.juego_id" :divisa_id="cupoSistemaData.divisa_id"/>
                        </template>
                    </v-container>
                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="primary" text :disabled="loading" @click="reset()">RESETEAR</v-btn>
                    <v-btn color="primary" :disabled="!isValid || loading" :loading="loading" @click="submit()">
                        <v-icon>mdi-content-save</v-icon>
                        <span v-if="this.cupoSistema">Editar</span>
                        <span v-else>Añadir</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import CupoSistemaForm from "./form"
import CupoForm from "./cupo-form"

import CupoService from "@/services/comercio/CupoService";

export default {
    components: {
        CupoSistemaForm,
        CupoForm
    },
    props: ['sistema', 'cupoSistema'],
    data: () => ({
        loading: false,
        dialog: false,

        context: 'new',

        cupoSistemaData: {},
        defaultCupoSistemaData: null,
        isCupoSistemaDataValid: false,

        cupoData: {},
        defaultCupoData: {},
        isCupoDataValid: false,

        divisa: null,
        divisaLoading: false,
    }),
    mounted: function () {
        this.reset();
    },
    computed: {
        isValid: function () {
            if (this.context == 'edit') return this.isCupoDataValid && Object.keys(this.cupoData).length;
            return this.isCupoSistemaDataValid && this.isCupoDataValid;
        }
    },
    methods: {
        close: function () {
            this.dialog = false;
        },
        submit: function () {
            this.loading = true;
            let action = null;
            let data = {
                sistema_id: this.sistema.id,
            };

            if (this.isCupoSistemaDataValid && Object.keys(this.cupoSistemaData).length) data = {
                ... data,
                ... this.cupoSistemaData
            };

            if (this.isCupoDataValid && Object.keys(this.cupoData).length) data = {
                ... data,
                ... this.cupoData,
            };

            if (this.cupoSistema) action = CupoService.update('sistema', this.cupoSistema.id, {
                cupo: data,
            });
            else action = CupoService.create('sistema', {
                cupo: data,
            });

            action.then(({ cupo }) => {
                this.$ui.notificate({message: 'CupoSistema guardado'});
                this.emit(cupo);
                this.close();
            }).catch(() => {
                this.$ui.notificate({message: 'Error guardando el cupoSistema', color: 'error'});
            }).finally(() => this.loading = false);
        },
        emit: function (data) {
            this.$emit('saved', data);
        },
        reset: function () {
            if (this.cupoSistema) {
                this.context = 'edit';
                this.cupoSistemaData = { ... this.cupoSistema};
                this.defaultCupoData = { ... this.cupoSistema};
            } else {
                this.context = 'new';
                this.cupoSistemaData = {};
                this.defaultCupoData = {};
            }
        }
    },
    watch: {
        dialog: function () {
            this.reset();
        },
    }
}
</script>
