<template>
    <v-form ref="form" style="width: 100%" v-model="valid">
        <div class="d-flex align-center mb-3">
            <div class="mr-3">
                <DateInput v-model="fields.date"/>
            </div>
            <div class="mr-3">
                <v-select
                    :items="stateList"
                    filled
                    hide-details
                    label="Estado"
                    v-model="fields.state"
                    :disabled="loading"
                    @change="changed('state', $event)"
                    />
            </div>
        </div>
        <template v-if="context == 'comercio'">
            <div class="d-flex align-center mb-3">
                <div class="mr-3"> <!--v-show="showMaster"-->
                    <v-select
                        :items="masterList"
                        :loading="isLoadingMasterList"
                        :disabled="isLoadingMasterList || !showMaster"
                        item-text="name"
                        item-value="id"
                        label="Master"
                        @change="changed('master_id', $event)"
                        v-model="fields.master_id"
                        hide-details
                        filled
                        >
                    </v-select>
                </div>
                <div class="mr-3"> <!--v-show="showComercializadora"-->
                    <v-select
                        :items="comercializadoraList"
                        :loading="isLoadingComercializadoraList"
                        :disabled="isLoadingComercializadoraList || !this.fields.master_id || !showComercializadora"
                        item-text="name"
                        item-value="id"
                        label="Comercializadora"
                        @change="changed('comercializadora_id', $event)"
                        v-model="fields.comercializadora_id"
                        hide-details
                        filled
                        />
                </div>
                <div class="mr-3"> <!--v-show="showGrupo"-->
                    <v-select
                        :items="grupoList"
                        :loading="isLoadingGrupoList"
                        :disabled="isLoadingGrupoList || !this.fields.comercializadora_id || !showGrupo"
                        item-text="name"
                        item-value="id"
                        label="Grupo"
                        @change="changed('grupo_id', $event)"
                        v-model="fields.grupo_id"
                        hide-details
                        filled
                        />
                </div>
                <div class="mr-3">
                    <v-select
                        :items="taquillaList"
                        :loading="isLoadingTaquillaList"
                        :disabled="isLoadingTaquillaList || !this.fields.grupo_id || !showTaquilla"
                        item-text="name"
                        item-value="id"
                        label="Taquilla"
                        @change="changed('taquilla_id', $event)"
                        v-model="fields.taquilla_id"
                        hide-details
                        filled
                        />
                </div>
            </div>
        </template>
        <div class="d-flex align-center mb-3">
            <template v-if="context == 'sistema'">
                <div class="mr-3">
                    <SelectorSistema 
                        v-model="fields.sistema_id" 
                        :disabled="loading"
                        :params="{ sistema_type_slug: sistema_type }"
                        />
                </div>
            </template>
            <div class="mr-3">
                <v-select
                    :items="juegoShaped"
                    item-text="name"
                    item-value="id"
                    label="Juego"
                    v-model="fields.juego_id"
                    hide-details
                    filled
                    :disabled="loading || loadingJuegoList" :loading="loadingJuegoList"
                    clearable
                    @change="changed('juego_id', $event)"
                    />
            </div>
            <div class="mr-3">
                <v-select
                    :items="juegoSorteoList"
                    item-text="horario"
                    item-value="id"
                    label="Sorteo"
                    v-model="fields.juego_sorteo_id"
                    hide-details
                    filled
                    :disabled="loading || loadingJuegoSorteoList || !fields.juego_id" :loading="loadingJuegoSorteoList"
                    @change="changed('juego_sorteo_id', $event)"
                    />
            </div>
            <div class="mr-3">
                <SelectorNumero v-model="fields.numero" :disabled="!this.fields.juego_id" :params="{juego_id: this.fields.juego_id}" filled/>
            </div>
            <div class="mr-3">
                <v-text-field
                    v-model="fields.search"
                    @change="changed('search', $event)"
                    append-icon="mdi-magnify"
                    filled
                    label="Número Control"
                    hide-details
                    clearable
                    />
            </div>
        </div>
        <div class="d-flex">
            <v-spacer/>
            <v-btn text class="mr-3" :disabled="loading" @click="reset()">Reiniciar Filtros</v-btn>
            <v-btn color="primary" :disabled="loading" :loading="loading" @click="search()"><v-icon>mdi-magnify</v-icon>Buscar</v-btn>
        </div>
    </v-form>
    
</template>

<script>
import Search from '@mixins/search';
import MasterService from '@services/comercio/MasterService';
import ComercializadoraService from '@services/comercio/ComercializadoraService';
import GrupoService from '@services/comercio/GrupoService';
import JuegoService from '@services/loteria/JuegoService';
import TaquillaService from '@services/comercio/TaquillaService';
import JuegoSorteoService from '@services/loteria/JuegoSorteoService';

export default {
    props: {
        'value': {}, 
        'loading': {},
        context: {
            default: 'comercio',
        },
        sistema_type: { default: 'sistema' },
    },
    mixins: [Search],
    data: function ()  {
        return {
            stateList: [
                {
                    text: 'Todos',
                    value: null,
                },
                {
                    text: 'Anulados',
                    value: '-1',
                },
                {
                    text: 'Vendidos',
                    value: '0',
                },
                {
                    text: 'Ganadores',
                    value: '1',
                },
                {
                    text: 'Pagados',
                    value: '2',
                }
            ],

            defaultFields: {
                search: null,
                state: null,
                master_id: null,
                comercializadora_id: null,
                grupo_id: null,
                juego_id: null,
                juego_sorteo_id: null,
                numero: null,
                taquilla_id: null,
                sistema_id: null,
                date: this.$luxon.local().toISODate(),
            },

            isLoadingMasterList: false,
            showMaster: true,
            masterList: [],

            isLoadingComercializadoraList: false,
            showComercializadora: true,
            comercializadoraList: [],

            isLoadingGrupoList: false,
            showGrupo: true,
            grupoList: [],
            
            loadingJuegoList: false,
            juegoList: [],

            loadingJuegoSorteoList: false,
            juegoSorteoList: [],
            
            isLoadingTaquillaList: false,
            showTaquilla: true,
            taquillaList: [],

            loadingTipoJuegoSelected: false,
            tipoJuegoSelected: null,

            dateMenu: false,
        }
    },
    computed: {
        juegoShaped: function () { 
            return this.juegoList.map((juego) => ({
                name: juego.loteria ? juego.loteria.name + '/' + juego.name: juego.name,
                id: juego.id,
            }));
        },
        defaultJuegoSlug: function () {
            return this.$store.getters['persist/default/defaultJuegoSlug'];
        }
    },
    created: function () {
        this.getMasterList();
        this.getJuegoList();
    },
    mounted: function () {
        this.preloadLists();
    },
    methods: {
        getMasterList: function () {
            this.masterList = [];
            this.isLoadingMasterList = true;
            MasterService.getList()
                .then(({ masterList }) => {
                    if (masterList.length == 1) 
                    {
                        this.fields.master_id = masterList[0].id;
                        this.showMaster = false;
                    }
                    this.masterList = [{id: null, name: 'Todos'},...masterList];
                })
                .finally(() => this.isLoadingMasterList = false);
        },
        getComercializadoraList: function (parent_id = null) {
            const master_id = this.fields.master_id || parent_id;
            this.comercializadoraList = [];
            if (!master_id) return;
            this.isLoadingComercializadoraList = true;
            ComercializadoraService.getList({ master_id })
                .then(({ comercializadoraList }) => {
                    if (comercializadoraList.length == 1) 
                    {
                        this.fields.comercializadora_id = comercializadoraList[0].id;
                        this.showComercializadora = false;
                    }
                    this.comercializadoraList = [{id: null, name: 'Todos'},...comercializadoraList];
                })
                .finally(() => this.isLoadingComercializadoraList = false);
        },
        getGrupoList: function (parent_id = null) {
            const comercializadora_id = this.fields.comercializadora_id || parent_id
            this.grupoList = [];
            if (!comercializadora_id) return;
            this.isLoadingGrupoList = true;
            GrupoService.getList({ comercializadora_id })
                .then(({ grupoList }) => {
                    if (grupoList.length == 1) 
                    {
                        this.fields.grupo_id = grupoList[0].id;
                        this.showGrupo = false;
                    }
                    this.grupoList = [{id: null, name: 'Todos'},...grupoList];
                })
                .finally(() => this.isLoadingGrupoList = false);
        },
        getTaquillaList: function (parent_id = null) {
            const grupo_id = this.fields.grupo_id || parent_id
            this.taquillaList = [];
            if (!grupo_id) return;
            this.isLoadingTaquillaList = true;
            TaquillaService.getList({ grupo_id })
                .then(({ taquillaList }) => {
                    if (taquillaList.length == 1) 
                    {
                        this.fields.taquilla_id = taquillaList[0].id;
                        this.showTaquilla = false;
                    }
                    this.taquillaList = [{id: null, name: 'Todos'},...taquillaList];
                })
                .finally(() => this.isLoadingTaquillaList = false);
        },

        getJuegoList: function () {
            this.juegoList = [];
            this.isLoadingJuegoList = true;
            JuegoService.getList()
                .then(({ juegoList }) => {
                    this.juegoList = juegoList;
                    if (!this.fields.juego_id) this.fields.juego_id = this.juegoList.find((juego) => juego.slug == this.defaultJuegoSlug)?.id;
                })
                .finally(() => this.isLoadingJuegoList = false);
        },
        getJuegoSorteoList: function (parent_id = null) {
            const juego_id = this.fields.juego_id || parent_id
            this.juegoSorteoList = [];
            if (!juego_id) return;
            this.isLoadingJuegoSorteoList = true;
            JuegoSorteoService.getList({ juego_id })
                .then(({ juegoSorteoList }) => {
                    this.juegoSorteoList = [
                        {
                            horario: 'Todos',
                            id: null,
                        },
                        ...juegoSorteoList
                    ];
                    const hora = this.$luxon.now().minus({hours: 1});
                    if (!this.fields.juego_sorteo_id) this.fields.juego_sorteo_id = juegoSorteoList.find((juegoSorteo) => hora <= this.$luxon.fromFormat(juegoSorteo.horario, 'HH:mm:ss'))?.id;
                    if (this.fields.juego_sorteo_id) {
                        this.changed('juego_sorteo_id', this.fields.juego_sorteo_id);
                        this.search()
                    }
                })
                .finally(() => this.isLoadingJuegoSorteoList = false);
        },
        preloadLists: function () {
            const defaults = this.default;
            if (defaults.master_id) {
                this.getComercializadoraList();
            }
            if (defaults.comercializadora_id) {
                this.getGrupoList();
            }
            if (defaults.grupo_id) {
                this.getTaquillaList();
            }
            if (defaults.juego_id) {
                this.getJuegoSorteoList();
            }
        },
        onChange: function (field) {
            switch(field) {
                case 'master_id':
                    this.fields.comercializadora_id = null;   
                break;
                case 'comercializadora_id':
                    this.fields.grupo_id = null;   
                break;
                case 'grupo_id':
                    this.fields.taquilla_id = null;   
                break;
                case 'juego_id':
                    this.fields.juego_sorteo_id = null;
                break;
            }
        }
    },
    watch: {
        'fields.juego_id': function (juego_id) {
            if (juego_id !== null) this.getJuegoSorteoList();
        },
        'fields.master_id': function (master_id) {
            if (master_id !== null) this.getComercializadoraList();
        },
        'fields.comercializadora_id': function (comercializadora_id) {
            if (comercializadora_id !== null) this.getGrupoList(); 
        },
        'fields.grupo_id': function (grupo_id) {
            if (grupo_id !== null) this.getTaquillaList();
        },
    }
}
</script>

<style>

</style>