<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" md="8">
                <v-card >
                    <v-card-title>
                        Información Básica
                    </v-card-title>
                    <v-card-text>
                        <LoteriaForm :loading="loading" ref="loteria-form" :loteria="loteria" v-model="loteriaValid" role="operadora"/>
                    </v-card-text>     
                    <v-card-actions>
                        <v-spacer/>
                        <v-btn 
                            large
                            color="primary"
                            :disabled="loading || !loteriaValid"
                            :loading="loading"
                            @click="submit()"
                            >
                            <v-icon>mdi-content-save</v-icon>
                            GUARDAR
                        </v-btn>
                    </v-card-actions>                 
                </v-card>
            </v-col>
        </v-row> 
    </v-container>
</template>

<script>
import LoteriaService from '@services/operadora/loteria/LoteriaService';

import LoteriaForm from '@components/entities/loteria/loteria/form';

export default {
    components: {
        LoteriaForm,
    },
    data: function () {
        return {
            
            loading: false,

            loteriaValid: false,

            loteria: null,

            loteriaList: [],
        };
    },
    created: function () {
        this.$store.dispatch('ui/layout/setTitle', 'Edición Loteria');

        if (this.$route.params?.id) {
            this.getLoteria(this.$route.params.id);
        }
    },
    methods: {
        submit: function () {
            this.loading = true;
            const loteria = this.$refs['loteria-form'].getData();

            const data = loteria;

            let action = null;

            if (this.loteria?.id) action = LoteriaService.update(this.loteria.id,data);
            else action = LoteriaService.create(data);

            action.then(({loteria}) => {
                //SUCCESS AND REDIRECT TO SEE CLIENT
                this.$router.push({ name: 'operadora.loteria.loteria' });
                this.$ui.notificate({message: loteria.name + ' Guardado', color: 'success'});
            })
            .catch(() => {
                //ERROR
            })
            .finally(() => this.loading = false);
        },
        getLoteria: function (id) {
            this.loading = true;
            LoteriaService.get(id).then(({loteria}) => {
                this.loteria = loteria;
            }).finally(() => {
                this.loading = false;
            })
        }
    },
}
</script>

<style>

</style>