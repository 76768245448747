<template>
	<v-card :outlined="outlined" :flat="flat">
        <template v-if="label.length">
            <v-card-title>
                <div class="d-flex justify-space-between" style="width: 100%">
                    <div>{{ label }}</div>
                </div>
            </v-card-title>
            <v-divider/>
        </template>
		<v-card-text>
			<v-treeview
				v-model="model"
				:load-children="fetch"
				:items="items"
				selected-color="indigo"
				selectable
				return-object
				selection-type="independent"
				expand-icon="mdi-chevron-down"
				dense
                :loading="loading"
                :disabled="disabled || loading"
				/>
		</v-card-text>
		<v-divider/>
		<v-card-actions>
			<v-btn text @click="model = []" :loading="loading" :disabled="loading || disabled" block>Reiniciar selección</v-btn>
		</v-card-actions>
        <template v-if="$slots.default">
            <div>
                <v-divider/>
                <div class="text-center">
                    <slot></slot>
                </div>
            </div>
        </template>
	</v-card>
</template>
<script>
import JuegoService from '@/services/loteria/JuegoService';

import MasterService from '@/services/comercio/MasterService';
import ComercializadoraService from '@/services/comercio/ComercializadoraService';
import GrupoService from '@/services/comercio/GrupoService';
import TaquillaService from '@/services/comercio/TaquillaService';
import SistemaService from '@/services/operadora/comercio/sistema/SistemaService';

const levelSet = {
    'comercio': ['master', 'comercializadora', 'grupo'],
    'sistema': ['sistema'],
};

const levelServices = {
	'juego': JuegoService,
	'master': MasterService,
	'comercializadora': ComercializadoraService,
	'grupo': GrupoService,
	'taquilla': TaquillaService,
    'sistema': SistemaService,
}

const getLevelService = function (level) {
	return levelServices[level];
}

const makeChildren = (id, level, foreign, name, type, child = []) => {
    let children = ({
        id,
        level,
        foreign,
        name,
        type,
    });

    if (child) {
        children['children'] = Array.isArray(child) ? child : [];
    }
    
    return children;
}

export default {
	props: {
		value: {},
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        loading: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        },
        flat: {
            type: Boolean,
            default: false
        }
	},
	data: () => ({
		items: [
            makeChildren('juego', 'juego', null, 'Operadora', 'juego', [
                makeChildren('comercio', 'comercio', null, 'Comercio', 'comercio', []),
                makeChildren('sistema', 'sistema', null, 'Sistema', 'sistema', []),
            ])
        ],
		isLoading: false,
		model: [],
	}),
	methods: {
		async fetch (item) {
            const type = item.type;

            const levels = levelSet[type];

			const indexLevel = levels.indexOf(item.level);

            let nextIndex = indexLevel + 1;
            let nextLevel = levels[nextIndex];

            if (levels.length == 1) {
                nextIndex = indexLevel;
                nextLevel = levels[nextIndex];
            }

            const Service = getLevelService(nextLevel);

			const params = { is_active: true };

			if (!['comercio', 'sistema'].includes(item.level)) params[`${item.level}_id`] = item.id.split(':')[1];

			const response = await Service.getList(params);
			
			const list = response[`${nextLevel}List`];

			list.forEach((comercio) => {
				item.children.push(
					makeChildren(`${nextLevel}:${comercio.id}`, nextLevel, comercio.id, comercio.name, type, indexLevel < (levels.length - 2))
				);
			});
		},
	},
	watch: {
		value: {
			deep: true,
			handler: function (value) {
				if (this.$lodash.isEqual(this.model, value)) return;
				this.model = value;
			}
		},
		model: {
			deep: true,
			handler: function (value) {
				if (this.$lodash.isEqual(this.value, value)) return;
				this.$emit('input', value);
			}
		},
        selectionType: {
            handler: function () {
                this.model = [];
            }
        }
	}
}
</script>