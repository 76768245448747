import axios from '@plugins/axios';

class CupoNumeroService {
    getList(filters = {}, options = {}) {
        return axios({
            url: `comercio/cupo/numero`,
            method: 'GET',
            params: filters,
            ...options
        });
    }
    create(data, options = {}) {
        return axios({
            url: `comercio/cupo/numero`,
            data: data,
            method: 'POST',
            ...options
        });
    }
    update(id, data, options = {}) {
        return axios({
            url: `comercio/cupo/numero/${id}`,
            data: data,
            method: 'PUT',
            ...options
        });
    }
    delete(id, options = {}) {
        return axios({
            url: `/comercio/cupo/numero/${id}`,
            method: 'DELETE',
            ...options
        });
    }
    clear(options = {}) {
        return axios({
            url: `/comercio/cupo/numero/`,
            method: 'DELETE',
           ...options
        });
    }
}

export const BaseCupoNumeroService = CupoNumeroService

export default new CupoNumeroService();