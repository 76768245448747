<template>
    <div>
        <ConsolidadoItem v-for="(reporte, index) in consolidado.list" :key="`reporte-consolidado-individual-${index}`" :reporte="reporte"></ConsolidadoItem>
        <v-data-table
            :headers="headers"
            :items="detallado.list"
            :items-per-page="15"
            :loading="detallado.loading"
        >
        <!-- eslint-disable-next-line -->
        <template v-slot:item.comercio="{ item }">
            <v-btn text small @click="open(item, 'window')">
                <v-icon class="mr-2" small>mdi mdi-eye-outline</v-icon>
                {{ item.comercio }} 
            </v-btn>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.juego="{ item }">
            {{ item.juego }} 
        </template>
        
        <!-- eslint-disable-next-line -->
        <template v-slot:item.venta="{ item }">
            {{ item.venta | currency }}
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.comision="{ item }">
            {{ item.comision | currency }}
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.premio="{ item }">
            {{ item.premio | currency }}
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.total="{ item }">
            <v-chip small label :color="item.percent > 100 ? 'error': 'primary'" class="font-weight-bold mr-2">
                {{ item.total | currency(item.divisa_code) }} {{' / '}} {{ item.percent | percent }}
            </v-chip>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.banqueo="{ item }">
            <span class="accent--text">{{ item.banqueo[0][0] | percent }} {{ item.banqueo[0][1]  | currency }}</span> / {{ item.banqueo[1][0] | percent }} {{ item.banqueo[1][1] | currency }} 
        </template>
        </v-data-table>
    </div>
    
</template>

<script>
import VentaService from '@services/reporte/VentaService';
import ConsolidadoItem from '@components/entities/reporte/venta/general/consolidado/item';
export default {
    components: {
        ConsolidadoItem,
    },
    props: {
        params: {
            defaults: function() {
                return {

                };
            },
        },
        context: {
            default: 'comercio',
        },
    },
    data: function() { 
        return {
            detallado: {
                list: [],
                count: 0,
                loading: false,       
            },
            consolidado: {
                list: [],
                count: 0,
                loading: false,
            }
        };
    },
    computed: {
        headers: function () {
            return [
                ... this.context == 'sistema' ? [{ text: 'Sistema', align: 'start', value: 'comercio' }] : [{ text: 'Comercio', align: 'start', value: 'comercio' }],
                { text: 'Juego', value: 'juego' },
                { text: 'Ventas', value: 'venta' },
                { text: 'Comisión', value: 'comision' },
                { text: 'Premios', value: 'premio' },
                { text: 'Total', value: 'total'},
                { text: 'Banqueo',align: 'center', value: 'banqueo' },
            ];
        },
        divisa: function () {
            return this.$store.getters['persist/divisa/selected'];
        }
    },
    mounted: function () {
        this.getList();
    },
    methods: {
        getList: function () {
            this.getListConsolidado();
            this.getListDetallado();
        },
        getListConsolidado: function () {
            this.consolidado.loading = true;
            this.consolidado.list = [];

            const params = { ... this.params, divisa_id: this.divisa.id };

            if (this.context == 'sistema') {
                params['group_by'] = 'sistema';
                params['comercio_type'] = 'sistema';
            }

            VentaService.reportePorSorteoConsolidado(params)
                .then(({reportList}) => {
                    this.consolidado.list = reportList;
                }).catch(() => {
                    
                }).finally(() => {
                    this.consolidado.loading = false;
                });
        },
        getListDetallado: function () {
            this.detallado.loading = true;
            this.detallado.list = [];

            const params = { ... this.params, divisa_id: this.divisa.id };

            if (this.context == 'sistema') {
                params['group_by'] = 'sistema';
                params['comercio_type'] = 'sistema';
            }

            VentaService.reportePorSorteoDetallado(params)
                .then(({reportList}) => {
                    this.detallado.list = reportList;
                }).catch(() => {
                    
                }).finally(() => {
                    this.detallado.loading = false;
                });
        },
        open: function (reporte, context = 'tab') {
            const query = {
                ... this.params,
            };
            query[`${reporte.comercio_type}_id`] = reporte.comercio_id;
            const route = this.$router.resolve({
                name: ({
                    'comercio': 'reporte.general',
                    'sistema': 'operadora.sistema.reporte.general',
                })[this.context],
                query: query,
            });
            if (context == 'tab') return window.open(new URL(route.href, window.location.origin), '_blank').focus();
            if (context == 'window') return window.open(new URL(route.href, window.location.origin), '_blank', 'titlebar=no, status=no, menubar=no, height=600 width=1000');
        }
    },
    watch: { 
        'params': function () {
            this.getList();
        },
        'divisa': function () {
            this.getList();
        }
    },
}
</script>
<style>

</style>