<template>
    <v-card>
        <v-card-text>
            <v-form ref="form" style="width: 100%" v-model="valid">
                <div class="d-flex align-center">
                    <div class="mr-3">
                        <DateInput
                            label="Fecha"
                            @change="changed('date', $event)"
                            v-model="fields.date"
                            dense
                            :disabled="loading"
                            />
                    </div>
                </div>
            </v-form>
        </v-card-text>
        <v-divider/>
        <v-card-actions class="pa-3">
            <div class="d-flex align-center" style="width: 100%">
                <slot/>
                <v-spacer/>
                <v-btn text class="mr-3" :disabled="loading" @click="reset()" small tile>Reiniciar Filtros</v-btn>
                <v-btn color="primary" :disabled="loading" :loading="loading" @click="search()" small tile><v-icon>mdi-magnify</v-icon>Buscar</v-btn>
            </div>
        </v-card-actions> 
    </v-card>
</template>

<script>
import Search from '@mixins/search';

export default {
    props: {
        loading: {},
    },
    mixins: [Search],
    data: function ()  {
        return {
            defaultFields: {
                date: this.$luxon.local().toSQLDate()
            },
        }
    },
}
</script>

<style>

</style>