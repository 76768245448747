<template>
    <div>
        <v-autocomplete
            :label="label"
            :items="list"
            v-model="input"
            item-value="id"
            item-text="name"
            :loading="loading"          
            :disabled="loading || disabled"
            :auto-select-first="true"
            :filled="filled"
            @change="changed($event)"
            clearable
            hide-details
            :multiple="multiple"
            :dense="dense"
            style="min-width: 250px"
            />
    </div>
</template>
<script>
import SelectorService from '@services/front/SelectorService';

export default {
    props: {
        value: {
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        }, 
        label: { 
            type: String, 
            default: 'Seleccione un juego' 
        },
        params: {
            type: Object,
        },
        filled: {
            type: Boolean,
            default: true,
        },
        juego: {
            default: () => {
                return null;
            },
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: false,
        }
    },
    data: function () {
        return {
            loading: false,
            list: [],
            input: this.multiple ? [] : null,
        }
    },
    computed: {
    },
    mounted: function () {
        this.get();
    },
    methods: {
        changed: function (value) {
            this.$emit('input', value);
            this.$emit('change', value);
            if (this.multiple) {
                this.$emit('update:juego', this.list.filter(juego => value.some(juego_id => juego_id == juego.id)));
            } else {
                this.$emit('update:juego', this.list.find(juego => juego.id == value));
            }
        },
        get: function () {
            this.loading = true;
            SelectorService
                .getJuego(this.params)
                .then(({ juegoList = [] }) => {
                    this.list = juegoList;
                    if (juegoList.length == 1) {
                        this.input = this.multiple ? [juegoList[0].id] : juegoList[0].id;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    watch: {
        value: function (value) {
            if (this.$lodash.isEqual(this.input, value)) return;
            this.input = this.multiple ? [value] : value;
        },
        input: function (value) {
            this.changed(value);
        },
        params: {
            handler: function () {
                this.$lodash.throttle(() => {
                    this.get();
                }, 500);
            },
            deep: true,
        }
    }
}
</script>