<template>
    <div>
        <v-card
            v-for="(cupoSistema, index) of list"
            :key="`cupo-sistema-${index}-${cupoSistema.id}`" 
            elevation="0" 
            tile 
            class="mb-1">
            <v-card-text>
                <div class="d-flex align-center">
                    {{ cupoSistema.juego.name }} - {{ cupoSistema.divisa.name }}
                    <v-spacer/>
                    <div>
                        <template v-if="cupoSistema.is_active">
                            <v-chip color="primary" small label link @click="set(cupoSistema, 'deactivate')">ACTIVO</v-chip>
                        </template>
                        <template v-else>
                            <v-chip color="error" small label link @click="set(cupoSistema, 'activate')">INACTIVO</v-chip>
                        </template>
                    </div>
                    <div class="ml-2">
                        <Modal :sistema="sistema" :cupoSistema="cupoSistema" @saved="updateElement($event)"/>
                    </div>
                </div>
                <v-container fluid class="subtitle-2 pa-0">
                    <v-row>
                        <v-col sm="2">
                            Cupo · {{ cupoSistema.cupo_jugada | currency(cupoSistema.divisa.code) }}
                        </v-col>
                        <v-col sm="3">
                            Min. Apuesta · {{ cupoSistema.min_apuesta | currency(cupoSistema.divisa.code) }}
                        </v-col>
                        <v-col sm="3">
                            Max. Apuesta · {{ cupoSistema.max_apuesta | currency(cupoSistema.divisa.code) }}
                        </v-col>
                        <v-col sm="2" class="text-right">
                            Comision · {{ cupoSistema.comision | percent }}
                        </v-col>
                        <v-col sm="2" class="text-right">
                            Banqueo · {{ cupoSistema.banqueo | percent }}
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import CupoService from '@/services/comercio/CupoService';

import Modal from './modal';

export default {
    components: {
        Modal,
    },
    props: {
        sistema: {},
    },
    data: function () {
        return {
            system: this.sistema,
            list: [],
        }
    },
    computed: {
    },
    methods: {
        set: function (cupoSistema, action){
            switch (action) {
                case 'activate': return this.update(cupoSistema, {
                    is_active: true,
                });
                case 'deactivate': return this.update(cupoSistema, {
                    is_active: false,
                });
            }
        },
        update: function (cupoSistema, data) {
            CupoService
                .update('sistema', cupoSistema.id, { cupo: data })
                .then(({cupo}) => {
                    this.updateElement(cupo);
                })
        },
        updateElement: function (cupoSistema) {
            const index = this.list.findIndex((item) => item.id == cupoSistema.id);

            if (index == -1) return this.list.push(cupoSistema);

            for (const key in cupoSistema) {
                if (Object.hasOwnProperty.call(cupoSistema, key)) {
                    const value = cupoSistema[key];
                    this.list[index][key] = value;
                }
            }
        }
    },
    mounted: function () {
        this.list =  this.system.cupo_sistema_list;
    }
}
</script>