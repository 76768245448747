import axios from '@plugins/axios';

class ProcessService {
    getList(params = {}, options = {}) {
        return axios({
            url: `operadora/resultados/process`,
            method: 'GET',
            params,
            ...options
        });
    }
    getReport(processId, options = {})  {
        return axios({
            url: `operadora/resultados/process/${processId}/report`,
            method: 'GET',
            ...options
        });
    }

    execTick(date, options = {}) {
        return axios({
            url: `operadora/resultados/process/tick/${date}`,
            method: 'POST',
            ...options
        });
    }
}

export const BaseProcessService = ProcessService

export default new ProcessService();