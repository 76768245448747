<template>
    <div>
        <v-autocomplete
            :label="label"
            :items="list"
            v-model="input"
            item-value="id"
            item-text="horario"
            :loading="loading"          
            :disabled="loading || disabled"
            :auto-select-first="true"
            :filled="filled"
            @change="changed($event)"
            clearable
            hide-details
            :multiple="multiple"
            :dense="dense"
            style="min-width: 250px"
            />
    </div>
</template>
<script>
import SelectorService from '@services/front/SelectorService';

export default {
    props: {
        value: {
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        }, 
        label: { 
            type: String, 
            default: 'Seleccione un Juego Sorteo' 
        },
        params: {
            type: Object,
        },
        filled: {
            type: Boolean,
            default: true,
        },
        juegoSorteo: {
            default: () => {
                return null;
            },
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: false,
        }
    },
    data: function () {
        return {
            loading: false,
            list: [],
            input: this.multiple ? [] : null,
            throttleGet: this.$lodash.throttle(() => {
                this.get();
            }, 500),
        }
    },
    computed: {
        isNumero: function () {
            return this.loading || !this.list.length;
        }
    },
    mounted: function () {
        this.throttleGet();
    },
    methods: {
        changed: function (value) {
            this.$emit('input', value);
            this.$emit('change', value);
            if (this.multiple) {
                this.$emit('update:juegoSorteo', this.list.filter(juegoSorteo => value.some(juego_sorteo_id => juego_sorteo_id == juegoSorteo.id)));
            } else {
                this.$emit('update:juegoSorteo', this.list.find(juegoSorteo => juegoSorteo.id == value));
            }
        },
        get: function () {
            this.loading = true;
            
            this.list = [];

            SelectorService
                .getJuegoSorteo(this.params)
                .then(({ juegoSorteoList = [] }) => {
                    this.list = juegoSorteoList;

                    if (!juegoSorteoList.length) {
                        this.input = this.multiple ? [] : null;
                        return;
                    }

                    if (juegoSorteoList.length == 1) {
                        this.input = this.multiple ? [juegoSorteoList[0].id] : juegoSorteoList[0].id;
                        return;
                    }

                    const hora = this.$luxon.now();
                    
                    const firstJuegoSorteo = juegoSorteoList[0];
                    const lastJuegoSorteo = juegoSorteoList[juegoSorteoList.length - 1];

                    if (this.multiple) this.input = juegoSorteoList
                                                        .filter(juegoSorteo => hora <= this.$luxon.fromFormat(juegoSorteo.horario, 'HH:mm:ss'))
                                                        .map(juegoSorteo => juegoSorteo.id);
                    else this.input = juegoSorteoList.find((juegoSorteo) => hora <= this.$luxon.fromFormat(juegoSorteo.horario, 'HH:mm:ss'))?.id;
                    
                    if (!this.$lodash.isEmpty(this.input)) return;

                    if (this.$lodash.isEmpty(this.input) && hora <= this.$luxon.fromFormat(firstJuegoSorteo.horario, 'HH:mm:ss')) {
                        if (this.multiple) this.input = juegoSorteoList.map(juegoSorteo => juegoSorteo.id);
                        else this.input = firstJuegoSorteo.id;
                        return;
                    }

                    if (this.$lodash.isEmpty(this.input) && hora >= this.$luxon.fromFormat(lastJuegoSorteo.horario, 'HH:mm:ss')) {
                        if (this.multiple) this.input = [lastJuegoSorteo.id];
                        else this.input = lastJuegoSorteo.id;
                        return;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    watch: {
        value: function (value) {
            if (this.$lodash.isEqual(this.input, value)) return;
            this.input = this.multiple ? [value] : value;
        },
        input: function (value) {
            this.changed(value);
        },
        params: {
            handler: function (value, oldParams) {
                if (this.$lodash.isEqual(value, oldParams)) return;
                this.throttleGet();
            },
            deep: true,
        }
    }
}
</script>