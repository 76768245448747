<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-card elevation="4" tile>
                    <div class="primary dark">
                        <v-card-title class="white--text">HERRAMIENTA CUPO NUMERO</v-card-title>
                        <v-tabs dark background-color="primary" v-model="active">
                            <v-tab>REGISTRO</v-tab>
                            <v-tab>LISTADO</v-tab>
                            <v-tab-item>
                                <Register @saved="active = 1"/>
                            </v-tab-item>
                            <v-tab-item>
                                <List/>
                            </v-tab-item>
                        </v-tabs>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import Register from '@components/entities/comercio/cupo-numero/crud';
import List from './list.vue';

export default {
    components: {
        Register,
        List,
    },
    data: function () {
        return {
            active: 0,
        }
    },
    computed: {
    },
    created: function () {
        this.$store.dispatch('ui/layout/setTitle', '');
    },
    methods: {
    },
    watch: {
    },
}
</script>