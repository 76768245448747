export default {
    namespaced: true,

    state: () => ({
        isDark:  true // window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches,
    }),

    getters: {
        isDark: state => state.isDark,
    },

    mutations: {
        setIsDark: (state, isDark) => {
            state.isDark = isDark;
        }
    },

    actions: {
        setIsDark: ({ commit }, isDark) => {
            commit('setIsDark', isDark);
        }
    }
}