import md5 from 'crypto-js/md5';

export default {
    install : function (Vue) {
        const colorObjectBasedOnSeed = function (seed) {
            const hash = md5(seed).toString();

            const r = parseInt(hash.substring(0, 2), 16);
            const g = parseInt(hash.substring(2, 4), 16);
            const b = parseInt(hash.substring(4, 6), 16);

            return {
                r,
                g,
                b
            }
        };

        const isColorObjectBright = function (colorObject) {
            const luminance = 0.2126 * colorObject.r + 0.7152 * colorObject.g + 0.0722 * colorObject.b;
            return luminance > 128;
        }


        Vue.prototype['$helper'] = {
            colorObjectBasedOnSeed,
            colorBaseOnSeed: function (seed) {
                const objectRGB = colorObjectBasedOnSeed(seed);
                return `rgb(${objectRGB.r}, ${objectRGB.g}, ${objectRGB.b})`;
            },
            isColorBright: function (color) {
                const regex = /rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)/;
                const matches = color.match(regex);

                if (!matches) return true; 
                
                return isColorObjectBright({
                    r: parseInt(matches[1]),
                    g: parseInt(matches[2]),
                    b: parseInt(matches[3]),
                })
            },
            isColorObjectBright
        };
    }
}