<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" md="4">
                <Search :loading="loading" @search="search($event)"></Search>
            </v-col>
            <v-col cols="12" md="8">
                <List :params="params" :loading.sync="loading"/>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import Search from '@components/entities/comercio/cupo-numero/search';
import List from '@components/entities/comercio/cupo-numero/list';
import CupoNumeroService from '@/services/comercio/CupoNumeroService';

{/* <div class="d-flex flex-wrap pa-2">
    <!-- <v-btn class="mb-1" color="primary" block small :disabled="!comercio_list.length">Limpiar Comercios Seleccionados</v-btn>
    <v-btn class="mb-1" color="primary" block small :disabled="!comercio_list.length">Limpiar Hijos de Comercios Seleccionados</v-btn>
    <v-btn color="accent" block small>Limpiar Todo</v-btn> -->
</div> */}

export default {
    components: {
        Search,
        List
    },
    data: function () {
        return {
            loading: false,  // Indica si se está cargando algún dato
            params: {},
        };
    },
    methods: {
        search(params) {
            this.params = params;
        },
        clear: function () {
            CupoNumeroService.clear();
        }
    }
}
</script>