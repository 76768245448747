<template>
    <v-card>
        <v-card-title>
            <div style="width: 100%" class="d-flex align-center justify-space-between">
                <div>Listado</div>
                <div>
                    <v-btn color="accent" small class="mr-2" :disabled="selfLoading" :loading="selfLoading" @click="clear">Eliminar Todo</v-btn>
                </div>
            </div>
        </v-card-title>
        <v-card-text>
            <div>
                <v-card class="mb-2" shaped outlined v-if="!list.length">
                    <template v-if="selfLoading">
                        <v-card-text class="text-center">
                            Cargando...
                        </v-card-text>
                    </template>
                    <template v-else>
                        <v-card-text class="text-center">
                            No hay datos disponibles
                        </v-card-text>
                    </template>
                </v-card>
                <template v-else>
                    <Item v-for="(cupo, index) of list" :key="`cupo-numero-item-${cupo.id}-${index}`" :cupo-numero="cupo" @deleted="deleted($event)"/>
                </template>
            </div>
        </v-card-text>
        <v-card-actions class="pb-4 d-flex justify-center">
            <v-btn color="accent" small @click="loadMore">Cargar más</v-btn>
        </v-card-actions> 
    </v-card>
</template>
<script>
import Item from './item';

import CupoNumeroService from '@/services/comercio/CupoNumeroService';

export default {
    components: {
        Item,
    },
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        params: {
            type: Object
        },
    },
    data: function () {
        return {
            auxLoading: false,
            is_clear_loading: false,
            list: [],
            count: 0,
            offset: 0,
            limit: 10,
        }
    },
    computed: {
        selfLoading: function () {
            return this.loading || this.auxLoading || this.is_clear_loading;
        }
    },
    methods: {
        getList: function (reset = true) {
            this.auxLoading = true;
            if (reset) {
                this.list = [];
                this.offset = 0;
            }
        
            CupoNumeroService.getList({ ... this.params, offset: this.offset, limit: this.limit })
                .then(({ cupo_numero_list = []}) => {
                    if (reset) this.list = cupo_numero_list;
                    else this.list.push(...cupo_numero_list);
                })
                .catch(() => {
                    this.$ui.notificate({
                        message: 'Error cargando la lista, intente mas tarde o notifique a soporte.',
                        color: 'error'
                    });
                })
                .finally(() => this.auxLoading = false);
        },
        loadMore: function () {
            this.offset += this.limit;
            this.getList(false);
        },
        clear: function () {
            this.$ui.confirm({
                message: `¿estás seguro de eliminar todos los cupos?`,
                textConfirm: 'Eliminar',
                textCancel: 'Cancelar',
                confirm: () => {
                    this.is_clear_loading = true;
                    CupoNumeroService.clear({... this.params})
                        .then(() => {
                            this.getList();
                        })
                        .catch(() => {
                            this.$ui.notificate({
                                message: 'Error limpiando la lista, intente mas tarde o notifique a soporte.',
                                color: 'error'
                            });
                        })
                        .finally(() => this.is_clear_loading = false);
                },
            });
        },
        deleted: function (cupoNumero) {
            this.list = this.list.filter(cupoNumroInList => cupoNumroInList.id != cupoNumero.id);
        }
    },
    watch: {
		params: {
			deep: true,
			handler: function () {
				this.getList();
			}
		},
        auxLoading: function (value) {
            this.$emit('update:loading', value);
        }
	},
    mounted: function () {
        this.getList();
    }
}
</script>