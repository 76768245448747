<template>
    <v-form ref="form" v-model="valid">
        <v-container fluid>
            <v-row dense>
                <v-col cols="12">
                    Fecha
                    <v-menu ref="dateMenu" v-model="dateMenu" offset-y min-width="auto" :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="fechaFormatted"
                                label="Fecha"
                                append-icon="mdi-calendar"
                                hide-details
                                filled 
                                readonly
                                :disabled="loading" :loading="loading"
                                v-bind="attrs"
                                v-on="on"
                                />
                        </template>
                        <v-date-picker
                            v-model="fields.fecha"
                            :rules="rules.fecha"
                            filled 
                            no-title
                            color="primary"
                            @change="changed('fecha'); dateMenu = false"
                            ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12">
                    Juego
                    <v-select
                        :items="juegoList"
                        item-text="name"
                        item-value="id"
                        label="Selecciona un juego"
                        v-model="fields.juego_id"
                        :rules="rules.juego_id"
                        :loading="loading || loadingJuegoList"                        
                        :disabled="loading || loadingJuegoList"
                        :readonly="!!juego"
                        filled 
                        hide-details="auto"
                        @change="changed('juego_id')"
                        >
                    </v-select>
                </v-col>
                <v-col cols="12">
                    Sorteo
                    <v-select
                        :items="juegoSorteoList"
                        item-text="horario"
                        item-value="id"
                        label="Selecciona un sorteo"
                        v-model="fields.juego_sorteo_id"
                        :rules="rules.juego_sorteo_id"
                        :loading="loading || loadingJuegoSorteoList"                        
                        :disabled="loading || loadingJuegoSorteoList || !fields.juego_id"
                        filled 
                        hide-details="auto"
                        @change="changed('juego_sorteo_id')"
                        >
                    </v-select>
                </v-col>
                
                <v-col cols="12">
                    Resultado
                    <Selector 
                        v-model="fields.resultado" 
                        @change="changed('resultado')"
                        label="Ingrese Resultado" 
                        :loading="loadingTipoJuegoSelected" 
                        :items="figuraList"
                        :disabled="loading || loadingTipoJuegoSelected || !tipoJuegoSelected"
                        />
                    <div v-if="juegoSelected && juegoSelected.slug == 'megaloton'">
                        <v-checkbox
                            label="ES MEGA PREMIO"
                            v-model="megalotonPremioEspecial"
                            />
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>
<script>
import Selector from '@components/entities/loteria/figura/selector';
import JuegoService from '@/services/operadora/loteria/JuegoService';
import JuegoSorteoService from '@/services/operadora/loteria/JuegoSorteoService';
import TipoJuegoService from '@/services/operadora/loteria/TipoJuegoService';

const defaultValues = {
    resultado: null,
    juego_id: null,
    juego_sorteo_id: null,
    fecha: null,
}

export default {
    components: {
        Selector
    },
    props: ['juego', 'value', 'loading'],
    data: function () {
        return {
            valid: false,

            loadingJuegoList: false,
            juegoList: [],

            loadingJuegoSorteoList: false,
            juegoSorteoList: [],

            loadingTipoJuegoSelected: false,
            tipoJuegoSelected: null,

            fields: (this.value && {...this.value , ...this.getDefaultFields()}) || this.getDefaultFields(),
            rules: {
                resultado: [
                    v => !!v || 'ERROR',
                ],
                juego_id: [
                    v => !!v || 'ERROR',
                ],
                juego_sorteo_id: [
                    v => !!v || 'ERROR',
                ],
                fecha: [
                    v => !!v || 'ERROR',
                ],
            },

            megalotonPremioEspecial: false,

            dateMenu: false,
        };
    },
    created: function () {
        this.getJuegoList();
    },
    mounted: function () {
    },
    computed: {
        fechaFormatted: function () {
            return this.$luxon.fromISO(this.fields.fecha).toFormat('dd/MM/y');
        },
        juegoSelected: function () {
            return this.juego || this.juegoList?.find((juego) => juego.id == this.fields.juego_id);
        },
        figuraList: function () { 
            if (this.tipoJuegoSelected?.figura_list)
                return this.tipoJuegoSelected.figura_list
            else
                return [];
        },
    },
    methods: {
        getJuegoList: function () {
            this.loadingJuegoList = true;
            
            this.juegoList = [];

            JuegoService
                .getListForResultado()
                .then(({ juegoList }) => {
                    this.juegoList = juegoList;
                })
                .finally(() => this.loadingJuegoList = false);
        },
        getJuegoSorteoList: function () {
            this.loadingJuegoSorteoList = true;

            const params = {is_active: true};
            if (this.fields.juego_id) params['juego_id'] = this.fields.juego_id;
            if (this.fields.fecha) params['fecha'] = this.fields.fecha;

            this.juegoSorteoList = [];
            this.fields.juego_sorteo_id = null;

            JuegoSorteoService
                .getListForResultado(params)
                .then(({ juegoSorteoList }) => {
                    this.juegoSorteoList = juegoSorteoList;
                })
                .finally(() => this.loadingJuegoSorteoList = false);
        },
        getTipoJuegoSelected: function () {
            this.tipoJuegoSelected = null;
            if (!this.juegoSelected) return;

            this.loadingTipoJuegoSelected = true;

            TipoJuegoService.get(this.juegoSelected.tipo_juego_id)
                .then(({ tipoJuego }) => {
                    this.tipoJuegoSelected = tipoJuego;
                }).finally(() => {
                    this.loadingTipoJuegoSelected = false;
                });
        },
        emit: function () {
            const data = { ...this.value };
            for (const property in this.fields) {
                if (Object.hasOwnProperty.call(this.fields, property)) {
                    if (!Object.hasOwnProperty.call(data, property) || data[property] != this.fields[property]) data[property] = this.fields[property];
                }
            }
            data['metadata'] = {};
            if (this.juegoSelected?.slug == 'megaloton') {
                data['metadata']['megapremio'] = this.megalotonPremioEspecial;
            }
            this.$emit('input', data);
        },
        changed: function (property) {
            switch (property) {
                case 'juego_id':
                    this.fields.juego_sorteo_id = null;
                    break;
                default:
                    break;
            }
            this.emit();
        },
        getDefaultFields: function () {
            if (this.$refs['form']) this.$refs['form'].resetValidation();
            return { ...defaultValues, ...{ fecha: this.$luxon.local().toISODate() } };
        }
    },
    watch: {
        'megalotonPremioEspecial': function () {
            this.emit();
        },
        'juegoSelected': function (juego, oldJuego) {
            if (juego.slug == 'megaloton' && oldJuego?.id != juego?.id) {
                this.megalotonPremioEspecial = false;
            }
        },
        'fields.juego_id': function (juego_id) {
            if (juego_id) this.getJuegoSorteoList();
            this.getTipoJuegoSelected();
        },
        value: function (value) {
            this.fields = (value && {...this.getDefaultFields(), ...value}) || this.getDefaultFields();
            this.$emit('update:valid', this.valid);
        },
        valid: function (valid) {
            this.$emit('update:valid', valid);
        }
    }
}
</script>