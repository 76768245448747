<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-card elevation="4" tile>
                    <div class="primary dark">
                        <v-card-title class="white--text">GESTIÓN DE RESULTADOS</v-card-title>
                        <v-tabs dark background-color="primary">
                            <v-tab>Resultados</v-tab>
                            <v-tab>Proveedores</v-tab>
                            <v-tab>Procesos</v-tab>
                            <v-tab-item>
                                <ResultadoList/>
                            </v-tab-item>
                            <v-tab-item>
                                <Provider/>
                            </v-tab-item>
                            <v-tab-item>
                                <Procesos/>
                            </v-tab-item>
                        </v-tabs>
                    </div>
                    <v-divider/>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ResultadoList from '@/components/entities/resultados/list';
import Provider from '@/components/entities/resultados/provider';
import Procesos from '@/components/entities/resultados/procesos';

export default {
    components: {
        ResultadoList,
        Provider,
        Procesos,
    },
    data: function () {
        return {
        }
    },
    computed: {
        
    },
    created: function () {
        this.$store.dispatch('ui/layout/setTitle', '');
    },
    methods: {
    },
    watch: {
    },
}
</script>

<style>

</style>