<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <LoteriaCard :loteria="loteria" :loading="loading">
                    <v-tabs
                        v-model="tab"
                        color="primary"
                        icons-and-text
                        >
                        <v-tabs-slider></v-tabs-slider>
                        
                        <v-tab v-if="loteria" href="#tab-juego">
                            {{loteria.dashboard.juego_count}} JUEGOS
                            <v-icon>mdi-account-box</v-icon>
                        </v-tab>
                        <v-tab href="#tab-usuario">
                            Usuarios
                            <v-icon>mdi-account-box</v-icon>
                        </v-tab>
                    </v-tabs>
                </LoteriaCard>
            </v-col>
            <v-col cols="12">
                <v-card elevation="5">
                    <v-tabs-items v-model="tab">
                        <v-tab-item value="tab-juego"><JuegoTab :loteria="loteria"/></v-tab-item>
                        <v-tab-item value="tab-usuario">Usuarios</v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import LoteriaService from '@services/operadora/loteria/LoteriaService';

import LoteriaCard from '@components/entities/loteria/loteria/card';

import JuegoTab from './components/tabs/juego';

export default {
    components: {
        LoteriaCard,
        JuegoTab,
    },
    data: () => ({
        tab: null,
        loading: false,
        loteria: null,
    }),
    created: function () {
        this.$store.dispatch('ui/layout/setTitle', 'Loterias');
        this.getLoteria(this.$route.params.id);
    },
    methods: {
        getLoteria: function (id) {
            this.loading = true;
            LoteriaService
                .get(id)
                .then(({ loteria }) => {
                    this.loteria = loteria;
                })
                .finally(() => this.loading = false);
        },
    },
}
</script>