<template>
    <div>
        <v-card>
            <v-card-text>
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" md="4">
                            <ComercioSelector v-model="fields.comercio_list" :disabled="loading" outlined label="Selecciona Comercio(s)"/>
                        </v-col>
                        <v-col cols="12" md="8">
                            <v-card outlined :loading="loading">
                                <v-card-title>Formulario</v-card-title>
                                <v-card-text>
                                    <div>
                                        <JuegoSelector v-model="fields.juego_id" :disabled="loading"/>
                                    </div>
                                    <div>
                                        <JuegoSorteoSelector 
                                            :disabled="!fields.juego_id || loading" 
                                            :params="{juego_id: fields.juego_id}" 
                                            v-model="fields.juego_sorteo_id" 
                                            :multiple="true"
                                            />
                                    </div>
                                    <div>
                                        <NumeroSelector 
                                            :disabled="!fields.juego_id || loading" 
                                            :params="{juego_id: fields.juego_id}" 
                                            v-model="fields.numero" :multiple="true"
                                            />
                                    </div>
                                    <div class="d-flex">
                                        <div>
                                            <DivisaSelector v-model="fields.divisa_id" :divisa.sync="divisa" :disabled="loading"/>
                                        </div>
                                        <div class="d-flex align-center">
                                            <v-divider vertical inset/>
                                            <div class="pa-2" style="border-bottom: 1px solid rgb(255 255 255 / 70%) !important;padding-bottom: 2px !important;">
                                                <div class="d-flex justify-center">
                                                    <v-checkbox
                                                        v-model="fields.is_bloqueado"
                                                        hide-details="auto"
                                                        class="ma-0 pa-0"
                                                        :disabled="loading"
                                                        ></v-checkbox>
                                                </div>
                                                <div>
                                                    <div v-if="fields.is_bloqueado">Desbloquear Número</div>
                                                    <div v-else>Bloquear Número</div>
                                                </div>
                                            </div>
                                            <v-divider vertical inset/>
                                        </div>
                                        <div class="flex-grow-1">
                                            <v-text-field v-model="fields.cupo_jugada" :disabled="fields.is_bloqueado || loading" filled label="Cupo Jugada" hide-details="auto">
                                                <template slot="append">
                                                    <v-icon v-if="fields.tipo_cupo == 'porcentual'">mdi-percent</v-icon>
                                                    <template v-else>
                                                        {{ divisa?.code }}
                                                    </template>
                                                </template>
                                            </v-text-field>
                                        </div>
                                        <!-- <div class="d-flex align-center">
                                            <v-divider vertical inset/>
                                            <div class="pa-2" style="border-bottom: 1px solid rgb(255 255 255 / 70%) !important;padding-bottom: 2px !important;">
                                                <div class="d-flex justify-center">
                                                    <v-switch
                                                        v-model="fields.tipo_cupo"
                                                        :disabled="fields.is_bloqueado || loading"
                                                        true-value="porcentual"
                                                        false-value="monto"
                                                        hide-details="auto"
                                                        class="ma-0 pa-0"
                                                        />
                                                </div>
                                                <div>
                                                    <div>{{ fields.tipo_cupo }}</div>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                    <v-divider inset class="my-5"/>
                                    <div v-if="!fields.comercio_list.length"  class="d-flex flex-wrap flex-row-reverse">
                                        <v-chip label outlined dark>
                                            <v-icon left small>mdi-beer</v-icon>
                                            Seleccione al menos un comercio
                                        </v-chip>
                                    </div>
                                    <div v-else class="d-flex flex-wrap flex-row-reverse">
                                        <v-chip
                                            v-for="(selection, i) in fields.comercio_list"
                                            :key="`comercio-selected-${i}-level-${selection.level}-foreign-${selection.foreign}`"
                                            label outlined dark
                                            class="mr-1 mb-1 px-4 py-5"
                                            >
                                            <div style="line-height: 1;">
                                                <div class="gray"><small>{{ selection.level }}</small></div>
                                                <div>{{ selection.name }}</div>
                                            </div>
                                        </v-chip>
                                    </div>
                                </v-card-text>
                                <v-divider/>
                                <v-card-actions>
                                    <div style="width: 100%">
                                        <div class="d-flex" style="width: 100%">
                                            <v-btn text @click="reset()" :disabled="loading" :loading="loading">Reset</v-btn>
                                            <v-spacer/>
                                            <v-btn text :disabled="!canSave || loading" :loading="loading" @click="save()">Añadir</v-btn>
                                            <v-btn text :disabled="!canSave || loading" :loading="loading" @click="save(true)">Añadir y Listar</v-btn>
                                        </div>
                                    </div>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
  </div>
</template>

<script>
import ComercioSelector from '@components/entities/comercio/selector';

import JuegoSelector from '@components/generic/selectors/juego';
import JuegoSorteoSelector from '@components/generic/selectors/juego-sorteo';
import DivisaSelector from '@components/generic/selectors/divisa';
import NumeroSelector from '@components/generic/selectors/numero';

import CupoNumeroService from '@/services/comercio/CupoNumeroService';

const defaultFields = {
    daterange: [],
    comercio_list: [],
    divisa_id: null,
    juego_id: null,
    juego_sorteo_id: [],
    numero: [],
    cupo_jugada: null,
    is_bloqueado: false,
    tipo_cupo: 'monto',
};

export default {
    components: {
        ComercioSelector,
        JuegoSelector,
        JuegoSorteoSelector,
        DivisaSelector,
        NumeroSelector,
    },
    data: function () {
        return {
            loading: false,
            fields: {
                ... defaultFields,
            },
            divisa: null,
        }
    },
    methods: {
        reset: function () {
            this.fields = { ... defaultFields };
        },
        save: function (keepRegistering = false) {
            this.loading = true;
            CupoNumeroService
                .create(this.detallado)
                .then(() => {
                    this.$ui.notificate({
                        message: 'Cupo Registrado Exitosamente',
                    });

                    if (keepRegistering) {
                        this.reset();
                    } else {
                        this.$emit('saved');
                    }
                })
                .catch(() => {
                    this.$ui.notificate({
                        message: 'Error registrnado cupo',
                        color: 'error'
                    });
                })
                .finally(() => this.loading = false);
        }
    },
    computed: {
        detallado: function () {
            const cupoDetalladoList = [];
            for (const comercio of this.fields.comercio_list) {
                for (const juego_sorteo_id of this.fields.juego_sorteo_id) {
                    for (const numero of this.fields.numero) {
                        cupoDetalladoList.push({
                            comercio_type: comercio.level,
                            comercio_id: comercio.foreign,
                            juego_id: this.fields.juego_id,
                            juego_sorteo_id,
                            numero,
                            divisa_id: this.fields.divisa_id,
                            cupo: this.fields.cupo_jugada,
                            is_bloqueado: this.fields.is_bloqueado,
                            is_percent: this.fields.tipo_cupo == 'porcentual',
                        });
                    }
                }
            }
            return cupoDetalladoList;
        },
        canSave: function () {
            if (!this.fields.comercio_list.length) return false;
            if (!this.fields.divisa_id) return false;
            if (!this.fields.juego_id) return false;
            if (!this.fields.juego_sorteo_id.length) return false;
            if (!this.fields.numero.length) return false;
            if (!this.fields.cupo_jugada && !this.fields.is_bloqueado) return false;
            return true;
        }
    },
    watch: {
        'fields.is_bloqueado': function () {
            this.fields.cupo_jugada = null;
            this.fields.tipo_cupo = 'monto';
        }
    }
}
</script>