<template>
    <div class="d-inline">
        <v-btn v-if="sistema" text small @click="dialog = !dialog"><v-icon small>mdi-pencil</v-icon> EDITAR</v-btn>
        <v-btn v-else text small @click="dialog = !dialog">AÑADIR SISTEMA</v-btn>
        <v-dialog v-model="dialog" width="600">
            <v-card>
                <v-card-title>
                    <h3>Sistema</h3>
                </v-card-title>
                <v-card-text class="pa-3">
                    <v-container fluid>
                        <SistemaForm :loading="loading" v-model="sistemaData" :default="defaultSistemaData" :valid.sync="isSistemaDataValid" :context="context"/>
                    </v-container>
                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="primary" text :disabled="loading" @click="reset()">RESETEAR</v-btn>
                    <v-btn color="primary" :disabled="!isValid || loading" :loading="loading" @click="submit()">
                        <v-icon>mdi-content-save</v-icon>
                        <span v-if="this.sistema">Editar sistema</span>
                        <span v-else>Añadir sistema</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import SistemaForm from "./form"

import SistemaService from '@services/operadora/comercio/sistema/SistemaService';

export default {
    components: {
        SistemaForm,
    },
    props: ['sistema'],
    data: () => ({
        loading: false,
        dialog: false,

        context: 'new',

        sistemaData: {},
        defaultSistemaData: null,
        isSistemaDataValid: false,
    }),
    mounted: function () {
        this.reset();
    },
    computed: {
        isValid: function () {
            return this.isSistemaDataValid;
        }
    },
    methods: {
        close: function () {
            this.dialog = false;
        },
        submit: function () {
            this.loading = true;
            let action = null;
            const data = {};

            if (this.isSistemaDataValid && Object.keys(this.sistemaData).length) data['sistema'] = this.sistemaData;

            if (this.sistema) action = SistemaService.update(this.sistema.id, data);
            else action = SistemaService.create(data);

            action.then(({ sistema }) => {
                this.$ui.notificate({message: 'Sistema guardado'});
                this.emit(sistema);
                this.close();
            }).catch(() => {
                this.$ui.notificate({message: 'Error guardando el sistema', color: 'error'});
            }).finally(() => this.loading = false);
        },
        emit: function (data) {
            this.$emit('saved', data);
        },
        reset: function () {
            if (this.sistema) {
                this.context = 'edit';
                this.defaultSistemaData = { ... this.sistema};
            } else {
                this.context = 'new';
                this.defaultSistemaData = {};
            }
            this.sistemaData = {};
        }
    },
    watch: {
        dialog: function () {
            this.reset();
        },
    }
}
</script>
