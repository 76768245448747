<template>
    <div>
        <v-text-field v-if="!list.length"
            v-model="input"
            @change="changed($event)" 
            :outlined="outlined"
            :filled="filled"
            :disabled="loading || disabled"
            hide-details
            label="Número"
            :dense="dense"
        />
        <v-autocomplete
            v-else
            label="Número/Figura"
            v-model="input"
            :items="list"
            :item-text="itemText"
            :loading="loading"          
            :disabled="loading || disabled"
            :auto-select-first="true"
            clearable
            :outlined="outlined"
            :filled="filled"
            @change="changed($event)"
            :multiple="multiple"
            hide-details
            :dense="dense"
            >
            <template v-slot:selection="data">
                {{ data.item.value }} {{ data.item.label }}
            </template>
            <template v-slot:item="data">
                {{ data.item.value }} {{ data.item.label }}
            </template>
        </v-autocomplete>
    </div>
</template>
<script>
import SelectorService from '@services/front/SelectorService';

export default {
    props: {
        value: {
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        }, 
        label: { 
            type: String, 
            default: 'Ingrese Numero' 
        },
        params: {
            type: Object,
            required: true,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        outlined: {
            type: Boolean,
            default: false,
        },
        filled: {
            type: Boolean,
            default: true,
        },
        dense: {
            type: Boolean,
            default: false,
        }
    },
    data: function () {
        return {
            loading: false,
            list: [],
            input: null,
        }
    },
    computed: {
        isNumero: function () {
            return this.loading || !this.list.length;
        }
    },
    mounted: function () {
        this.get();
    },
    methods: {
        changed: function (value) {
            let val = value;
            
            if (this.multiple && !this.list.length) val = value.split(',');

            this.$emit('input', val);
            this.$emit('change', val);
        },
        get: function () {
            this.loading = true;
            SelectorService
                .getNumero(this.params)
                .then(({ numeroList = [] }) => {
                    this.list = numeroList;
                    this.input = this.value;
                })
                .catch(() => {

                })
                .finally(() => {
                    this.loading = false;
                });
        },
        itemText(item) {
            return `${item.value} ${item.label}`;
        }
    },
    watch: {
        value: function (value) {
            this.input = value;
        },
        params: {
            handler: function () {
                this.$lodash.throttle(() => {
                    this.get();
                }, 500);
            },
            deep: true,
        },
        'params.juego_id': function () {
            this.get();
        },
    }
}
</script>