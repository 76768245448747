<template>
    <v-card flat @click="toggleColorMode">
        <v-card-text class="px-5 py-0">
            <small class="overline" v-if="isDark">MODO CLARO</small>
            <small class="overline" v-else>MODO OSCURO</small>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    computed: {
        isDark: function () {
            return this.$store.getters['persist/layout/isDark'];
        },
    },
    methods: {
        toggleColorMode: function () {
            this.$vuetify.theme.dark = !this.isDark;
            this.$store.dispatch('persist/layout/setIsDark', !this.isDark);
        }
    }
}
</script>