<template>
<div>
    <v-form ref="form" class="d-flex align-center mb-3" style="width: 100%" v-model="valid">
        <div class="mr-3">
            <v-select
                :items="juegoList"
                item-text="name"
                item-value="id"
                label="Juego"
                v-model="fields.juego_id"
                hide-details
                filled
                dense
                :disabled="loading || loadingJuegoList" :loading="loadingJuegoList"
                @change="changed('juego_id')"
                />
        </div>
        <div class="mr-3">
            <v-select
                :items="juegoSorteoList"
                item-text="horario"
                item-value="id"
                label="Sorteo"
                v-model="fields.juego_sorteo_id"
                hide-details
                filled
                dense
                :disabled="loading || loadingJuegoSorteoList || !fields.juego_id" :loading="loadingJuegoSorteoList"
                @change="changed('juego_sorteo_id')"
                />
        </div>
        <div class="mr-3">
            <v-select
                :items="isAnuladoList"
                filled
                hide-details
                label="Estado"
                v-model="fields.is_anulado"
                :disabled="loading"
                @change="changed('is_anulado')"
                dense
                />
        </div>
        <div>
            <v-menu ref="dateMenu" v-model="dateMenu" offset-y min-width="auto" :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="fechaFormatted"
                        label="Fecha"
                        append-icon="mdi-calendar"
                        hide-details
                        filled
                        readonly
                        :disabled="loading"
                        v-bind="attrs"
                        v-on="on"
                        dense
                        />
                </template>
                <v-date-picker
                    v-model="fields.fecha"
                    no-title
                    color="primary"
                    dense
                    @change="changed('fecha'); dateMenu = false"
                    :allowed-dates="(date) => date <= new Date().toISOString().substr(0, 10)"
                    ></v-date-picker>
            </v-menu>
        </div>
    </v-form>
    <div class="d-flex">
        <slot name="actions"/>
        <v-spacer/>
        <v-btn small text class="mr-3" :disabled="loading" @click="reset()">Reiniciar Filtros</v-btn>
        <v-btn small color="primary" :disabled="loading" :loading="loading" @click="search()"><v-icon>mdi-magnify</v-icon>Buscar</v-btn>
    </div>
</div>
</template>

<script>
import JuegoService from '@services/loteria/JuegoService';
import JuegoSorteoService from '@services/loteria/JuegoSorteoService';
import Search from '@mixins/search';

export default {
    props: ['value', 'loading'],
    mixins: [Search],
    data: function ()  {
        return {
            isAnuladoList: [
                {
                    text: 'Todos',
                    value: null,
                },
                {
                    text: 'Activos',
                    value: false,
                },
                {
                    text: 'Anulados',
                    value: true,
                },
            ],

            defaultFields: {
                juego_id: null,
                juego_sorteo_id: null,
                is_anulado: false,
                fecha: this.$luxon.local().toISODate(),
            },

            loadingJuegoList: false,
            juegoList: [],

            loadingJuegoSorteoList: false,
            juegoSorteoList: [],

            dateMenu: false,
        }
    },
    computed: {
        fechaFormatted: function () {
            return this.$luxon.fromISO(this.fields.fecha).toFormat('dd/MM/yyyy');
        }
    },
    created: function () {
        this.getJuegoList();
    },
    methods: {
        getJuegoList: function () {
            this.loadingJuegoList = true;

            this.juegoList = [];
            this.fields.juego_id = null;

            JuegoService.getList()
                .then(({ juegoList }) => {
                    this.juegoList = [{name: 'Todos', id: null}, ...juegoList];
                }).finally(() => {
                    this.loadingJuegoList = false;
                });
        },
        getJuegoSorteoList: function () {
            this.loadingJuegoSorteoList = true;

            this.juegoSorteoList = [];
            this.fields.juego_sorteo_id = null;

            const params = {is_active: true};
            if (this.fields.juego_id) params['juego_id'] = this.fields.juego_id;

            JuegoSorteoService
                .getList(params)
                .then(({ juegoSorteoList }) => {
                    this.juegoSorteoList = [{horario: 'Todos', id: null}, ...juegoSorteoList];
                })
                .finally(() => this.loadingJuegoSorteoList = false);
        },
    },
    watch: {
        'fields.juego_id': function (juegoId) {
            if (juegoId !== null) {
                this.getJuegoSorteoList();
            } else {
                this.juegoSorteoList = [];
                this.fields.juego_sorteo_id = null;
            }
        },
    }
}
</script>

<style>

</style>