<template>
<v-card flat elevation="0">
    <v-card-title>
        PROCESOS DE ADQUISICIÓN DE RESULTADOS
    </v-card-title>
    <v-card-text>
        <div class="mb-4">
            <Search @search="params = $event">
                <v-btn small color="primary" @click="execTick" :loading="loading" :disabled="loading" tile>EJECUTAR TICK DE PROCESOS</v-btn>
            </Search>
        </div>
        <v-card>
            <v-card-text>
                <template v-if="loading">
                    <v-card flat>
                        <v-card-text class="text-center">
                            CARGANDO...
                        </v-card-text>
                    </v-card>
                </template>
                <template v-else>
                    <v-card flat v-if="!list.length">
                        <v-card-text class="text-center">
                            SIN PROCESOS
                        </v-card-text>
                    </v-card>
                    <template v-else>
                        <Process 
                            v-for="(process, index) of list" 
                            :key="`process-list-${index}-${process.id}`" 
                            :process="process"
                            class="mb-2"
                            />
                    </template>
                </template>
            </v-card-text>
        </v-card>
    </v-card-text>
</v-card>
</template>
<script>
import ProcessService from '@/services/operadora/resultados/ProcessService';

import Search from './search.vue';
import Process from './process.vue';

export default {
    components: {
        Search,
        Process
    },
    data() {
        return {
            params: {
                date: this.$luxon.local().toSQLDate()
            },
            list: [],
            loading: false,
        }
    },
    methods: {
        execTick: function () {
            this.loading = true;
            ProcessService
                .execTick(this.params.date)
                .then(() => {
                    this.$ui.notify('Tick ejecutado correctamente');
                    this.getList();
                }).catch((error) => {
                    console.error(error);
                    this.loading = false;
                });

        },
        getList: function () {
            this.loading = true;
            ProcessService
                .getList({ ... this.params })
                .then(({ processList = [] }) => {
                    this.list = processList;
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                }); 
        }
    },
    mounted: function () {
        this.getList();
    },
    watch: {
        params: {
            handler: function () {
                this.getList();
            },
            deep: true,
        }
    }
}
</script>