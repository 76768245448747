<template>
    <v-card outlined>
        <v-card-text class="d-flex justify-space-between">
            <div>
                <h3 class="ma-0">{{ process.horario }}</h3>
            </div>
            <div>
                <v-chip 
                    v-if="process.is_active"
                    label
                    color="primary"
                    small
                    >ACTIVO
                </v-chip>
                <v-chip
                    v-else
                    label
                    small
                    >FINALIZADO
                </v-chip>
            </div>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
            <v-expansion-panels flat v-model="openReport">
                <v-expansion-panel>
                    <v-expansion-panel-header class="pa-1 pl-2 text-uppercase">
                        Resultados
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-simple-table dense>
                            <thead>
                                <tr>
                                    <th>Provider</th>
                                    <th>Juego</th>
                                    <th>Resultado</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(report, index) in list" :key="`report-item-index-${index}`">
                                    <td>
                                        <v-chip 
                                            label 
                                            small 
                                            :color="$helper.colorBaseOnSeed(report.provider)" 
                                            :dark="!$helper.isColorBright($helper.colorBaseOnSeed(report.provider))"
                                            >
                                            <b>{{ report.provider }}</b>
                                        </v-chip>
                                    </td>
                                    <td>
                                        <v-chip 
                                            label 
                                            small
                                            color="primary"
                                            >
                                            <b>{{ report.juego }}</b>
                                        </v-chip>
                                    </td>
                                    <td>{{ report.resultado }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card-actions>
    </v-card>
</template>
<script>
import ProcessService from '@/services/operadora/resultados/ProcessService';

export default {
    props: {
        process: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            openReport: false,
            loading: false,
            list: [],
        }
    },
    methods: {
        getReport: function () {
            this.loading = true;
            ProcessService.getReport(this.process.id)
               .then(({ report = [] }) => {
                    this.list = report;
                })
               .catch(error => {
                   console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    },
    watch: {
        openReport: function (open) {
            if (open !== undefined) this.getReport();
        }
    },
    created() {
    },
}
</script>