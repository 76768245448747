import axios from '@plugins/axios';

class SelectorService {
    getNumero(filters = {}, options = {}) {
        return axios({
            url: `front/selectors/numero`,
            method: 'GET',
            params: filters,
            ...options
        });
    }
    getDivisa(filters = {}, options = {}) {
        return axios({
            url: `front/selectors/divisa`,
            method: 'GET',
            params: filters,
            ...options
        });
    }
    getJuego(filters = {}, options = {}) {
        return axios({
            url: `front/selectors/juego`,
            method: 'GET',
            params: filters,
            ...options
        });
    }
    getJuegoSorteo(filters = {}, options = {}) {
        return axios({
            url: `front/selectors/juego-sorteo`,
            method: 'GET',
            params: filters,
            ...options
        });
    }
    getSistema(filters = {}, options = {}) {
        return axios({
            url: `front/selectors/sistema`,
            method: 'GET',
            params: filters,
            ...options
        });
    }

    getSistemaType(filters = {}, options = {}) {
        return axios({
            url: `front/selectors/sistema/type`,
            method: 'GET',
            params: { ... filters, is_active: true },
            ...options
        });
    }
}

export const BaseSelectorService = SelectorService

export default new SelectorService();