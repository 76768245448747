<template>
    <div>
        <v-card outlined tile>
            <v-card-text>
                <div v-if="!isLoading">
                    <Item
                        v-if="divisaDefault"
                        :divisa="divisaDefault"
                        :cupo="divisaDefault.cupo"
                        @set="set($event)"
                        >
                        <template v-slot:actions v-if="divisaDefault.cupo">
                            <div class="d-flex align-center">
                                <v-switch
                                    class="ma-0"
                                    v-model="divisaDefault.cupo.is_active" 
                                    :loading="divisaDefault.cupo.isActiveLoading"
                                    :disabled="divisaDefault.cupo.isActiveLoading || divisaDefault.cupo.isLoading"
                                    hide-details="auto"
                                    @change="handleActiveSwitch($event, divisaDefault.cupo)"
                                    />
                                <v-btn icon @click="edit(divisaDefault)" :loading="divisaDefault.cupo.isLoading" :disabled="divisaDefault.cupo.isLoading">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </div>
                        </template>
                    </Item>
                    <div v-if="divisaDefault && filteredDivisaList.length" class="mt-1 mb-4">
                        <v-subheader style="height: unset">OTRAS DIVISAS</v-subheader>
                        <v-divider></v-divider>
                    </div>
                    <Item
                        v-for="(divisa, index) in filteredDivisaList" 
                        :key="`juego-cupo-juego-form-item-${index}-${divisa.id}`"
                        :divisa="divisa"
                        :cupo="divisa.cupo"
                        :class="{
                            'mb-3': index < (filteredDivisaList.length - 1),
                        }"
                        @set="set($event)"
                        >
                        <template v-slot:actions>
                            <div class="d-flex align-center">
                                <v-switch
                                    class="ma-0"
                                    v-model="divisa.cupo.is_active" 
                                    :loading="divisa.cupo.isActiveLoading"
                                    :disabled="divisa.cupo.isActiveLoading || divisa.cupo.isLoading"
                                    hide-details="auto"
                                    @change="handleActiveSwitch($event, divisa.cupo)"
                                    />
                                <v-btn icon @click="edit(divisa)" :loading="divisa.cupo.isLoading" :disabled="divisa.cupo.isLoading">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </div>
                        </template>
                    </Item>
                </div>
                <div v-if="isLoading" class="d-flex justify-center align-center" style="width: 100%">
                    <v-progress-circular
                        :size="70"
                        :width="7"
                        color="primary"
                        indeterminate
                        />
                </div>
            </v-card-text>
        </v-card>
        <Modal :cupo="cupo" :open.sync="open" @saved="getCupoList()" :owner-context="context" :owner="owner" :has-limit="hasLimit" :divisa="divisa"/>
  </div>
</template>

<script>
import CupoService from '@services/comercio/CupoService';

import Item from './item';
import Modal from './modal';

export default {
    components: {
        Item,
        Modal,
    },
    props: {
        context: {
            type: String,
            required: true,
        },
        owner: {
            type: Object,
            required: true,
        },
        divisaList: {
            type: Array,
            required: true,
            default: () => [],
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        hasLimit: {
            type: Boolean,
            required: false,
            default: false,
        },
        divisaAmount: {
            type: Number,
            default: null,
        }
    },
    data: () => ({
        cupoList: [],
        isCupoLoading: true,
        cupo: null,
        open: false,
        divisa: null,
    }),
    mounted: function () {
        this.getCupoList();
    },
    methods: {
        getCupoList: function () {
            this.cupo = null;
            this.cupoList = [];

            this.isCupoLoading = true;
            
            CupoService
                .getList(this.context, { ...this.owner })
                .then(({ cupoList = [] }) => {
                    this.cupoList = cupoList;
                })
                .finally(() => this.isCupoLoading = false);
        },
        handleActiveSwitch: function ($evt, cupo){
            if ($evt) this.activate(cupo)
            else this.deactivate(cupo)
        },
        activate: function (cupoToActivate) {
            this.$set(cupoToActivate, 'isActiveLoading', true);
            CupoService
                .activate(this.context, cupoToActivate.id)
                .then(({ cupo }) => {
                    this.$ui.notificate({message: 'Activado', color:'success'});
                    this.$set(cupoToActivate, 'is_active', cupo.is_active);
                })
                .finally(() => {
                    this.$set(cupoToActivate, 'isActiveLoading', false); 
                });
        },
        deactivate: function (cupoToDeactivate) {
            this.$ui.confirm({
                message: "¿Desea desactivar el cupo?",
                textConfirm: 'Desactivar',
                textCancel: 'Cancelar',
                confirm: () => {
                    this.$set(cupoToDeactivate, 'isActiveLoading', true);
                    CupoService
                        .deactivate(this.context, cupoToDeactivate.id)
                        .then(({ cupo }) => {
                            this.$ui.notificate({message: 'Desactivado', color:'success'});
                            this.$set(cupoToDeactivate, 'is_active', cupo.is_active); 
                        })
                        .finally(() => { this.$set(cupoToDeactivate, 'isActiveLoading', false); });
                },
                cancel: () => {
                    this.$set(cupoToDeactivate, 'is_active', true); 
                }
            });
        },
        edit: function (divisa) {
            this.cupo = divisa.cupo;
            this.divisa = divisa;
            this.open = true;
        },
        set: function (divisa) {
            this.cupo = null;
            this.divisa = divisa;
            this.open = true;
        }
    },
    computed: {
        isLoading: function () {
            return this.loading || this.isCupoLoading;
        },
        processedDivisaList: function () {
            return this.divisaList
                .map(
                    divisa => (
                        {
                            ...divisa,
                            cupo: this.cupoList.find(cupo => cupo.divisa_id == divisa.id)
                        }
                    )
                );
        },
        filteredDivisaList: function () {
            return this.processedDivisaList.filter(divisa => !divisa.is_default);
        },
        filteredCupoList: function () {
            return this.cupoList.filter(cupo => !cupo.divisa.is_default);
        },
        divisaDefault: function () {
            return this.processedDivisaList.find(divisa => divisa.is_default);
        }
    },
    watch: {
        cupoList: function (cupoList) {
            this.$emit('update:list', cupoList);
        }
    }
}
</script>