import Users from '@pages/operadora/users';

import Loteria from '@pages/operadora/loteria';
import LoteriaForm from '@pages/operadora/loteria/form';
import LoteriaView from '@pages/operadora/loteria/view';

import Juego from '@pages/operadora/juego';
import Juegos from '@pages/operadora/juego/view';

import Resultados from '@pages/operadora/resultados';
import Premiacion from '@pages/operadora/premiacion';

import Divisa from '@pages/operadora/divisa';
import CupoNumero from '@pages/cupo-numero';
// import CupoNumeroRegistrar from '@pages/cupo-numero/registrar';

export default [
    {
        path: '/operadora/loteria/administracion/users',
        name: 'operadora.administracion.users',
        component: Users,
    },
    {
        path: '/operadora/loteria/loteria',
        name: 'operadora.loteria.loteria',
        component: Loteria,
    },
    {
        path: '/operadora/loteria/loteria/nuevo',
        name: 'operadora.loteria.loteria.nuevo',
        component: LoteriaForm,
    },
    {
        path: '/operadora/loteria/loteria/editar/:id',
        name: 'operadora.loteria.loteria.editar',
        component: LoteriaForm,
    },
    {
        path: '/operadora/loteria/loteria/:id',
        name: 'operadora.loteria.loteria.view',
        component: LoteriaView,
    },
    {
        path: '/operadora/loteria/juego',
        name: 'operadora.loteria.juego',
        component: Juego,
    },
    {
        path: '/operadora/loteria/resultados',
        name: 'operadora.loteria.resultados',
        component: Resultados,
    },
    {
        path: '/operadora/juego/view',
        name: 'operadora.juego',
        component: Juegos,
    },
    {
        path: '/operadora/divisa',
        name: 'operadora.divisa',
        component: Divisa,
    },
    {
        path: '/cupo/numero',
        name: 'operadora.cupo.numero',
        component: CupoNumero,
    },
    {
        path: '/cupo/numero/registrar',
        name: 'operadora.cupo.numero.registrar',
        component: CupoNumero,
    },
    {
        path: '/operadora/premiacion',
        name: 'operadora.premiacion',
        component: Premiacion,
    },
];
