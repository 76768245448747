<template>
    <v-card>
        <v-card-title>
            <div class="d-flex align-center" style="width: 100%">
                <v-spacer/>
                <v-btn text class="mr-3" :disabled="loading" @click="reset()">Reiniciar Filtros</v-btn>
                <v-btn color="primary" :disabled="loading" :loading="loading" @click="search()"><v-icon>mdi-magnify</v-icon>Buscar</v-btn>
            </div>
        </v-card-title>
        <v-divider/>
        <v-card-text>
            <v-form ref="form" style="width: 100%" v-model="valid">
                <div class="d-flex align-center flex-wrap mb-3">
                    <div class="flex-grow-1 mb-2">
                        <SelectorDivisa
                            label="Divisa"
                            @change="changed('divisa_id', $event)"
                            v-model="fields.divisa_id"
                            dense
                            :disabled="loading"
                            />
                    </div>
                    <div class="flex-grow-1 mb-2">
                        <SelectorJuego
                            label="Juego"
                            @change="changed('juego_id', $event)"
                            v-model="fields.juego_id"
                            dense
                            :disabled="loading"
                            />
                    </div>
                    <div class="flex-grow-1 mb-2">
                        <SelectorJuegoSorteo
                            label="Sorteo"
                            @change="changed('juego_id', $event)"
                            v-model="fields.juego_sorteo_id"
                            :params="{juego_id: fields.juego_id}"
                            :disabled="!fields.juego_id || loading"
                            dense
                            />
                    </div>
                    <div class="flex-grow-1 mb-2">
                        <SelectorNumero 
                            v-model="fields.numero" 
                            :disabled="!this.fields.juego_id || loading"
                            :params="{juego_id: this.fields.juego_id}" 
                            filled
                            dense
                            />
                    </div>
                </div>
                <div>
                    <ComercioSelector v-model="comercio_list" :loading="loading" outlined/>
                </div>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
import ComercioSelector from '@components/entities/comercio/selector';
import Search from '@mixins/search';

export default {
    components: {
        ComercioSelector
    },
    props: {
        loading: {},
    },
    mixins: [Search],
    data: function ()  {
        return {
            comercio_list: [],
            defaultFields: {
                date: this.$luxon.local().toSQLDate(),

                juego_id: null,
                juego_sorteo_id: null,

                numero: null,

                divisa_id: [],
                comercio: [],
            },
        }
    },
    computed: {
       
    },
    created: function () {
    },
    mounted: function () {
    },
    methods: {
    },
    watch: {
        comercio_list: function (comercio_list) {
            const list = comercio_list.map(comercio => ({
                type : comercio.level, 
                id: comercio.foreign, 
            }));
            this.fields.comercio = list;
            this.changed('comercio', list);
        }
    }
}
</script>

<style>

</style>