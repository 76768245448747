<template>
    <v-card flat>
        <v-card-title>
            GESTION DE RESULTADOS
        </v-card-title>
        <v-card-text>
            <v-card class="mb-2">
                <v-card-text>
                    <JuegoResultadoSearch v-model="params" :loading="loading" @search="getJuegoResultadoList()">
                        <template v-slot:actions="">
                            <v-btn color="secondary" @click="openResultadoModal()" small tile><v-icon>mdi-account-box</v-icon> AÑADIR RESULTADO</v-btn>
                        </template>
                    </JuegoResultadoSearch>
                </v-card-text>
            </v-card>
            <!--Available :params="params"/-->
            <v-card>
                <v-card-text>
                    <v-data-table 
                        :headers="headers" 
                        :items="juegoResultadoList" 
                        :items-per-page="-1"
                        :options.sync="options" 
                        :server-items-length="juegoResultadoCount" 
                        :loading="loading">
                        <!-- eslint-disable-next-line -->
                        <template v-slot:item.juego="{ item }">
                            {{ item.juego.name }} - {{ item.juego_sorteo.horario }}
                        </template>
                        <!-- eslint-disable-next-line -->
                        <template v-slot:item.resultado="{ item }">
                            <v-chip label v-if="item.figura" color="primary">{{item.figura.value}} - {{item.figura.label}}</v-chip>
                            <v-chip label v-else color="primary">{{item.resultado}}</v-chip>
                        </template>
                        <!-- eslint-disable-next-line -->
                        <template v-slot:item.actions="{ item }">
                            <ActionAnular v-if="!item.is_anulado" :juegoResultado="item" @anulado="resultadoAnulado($event)"/>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-card-text>
        <JuegoResultadoModal :open.sync="isModalResultadoOpen" @saved="postSaved" data-backdrop="static"/>
    </v-card>
</template>
<script>
import JuegoResultadoService from '@services/operadora/loteria/JuegoResultadoService';

import JuegoResultadoModal from'@components/entities/loteria/juego-resultado/modal-form';
import JuegoResultadoSearch from '@components/entities/loteria/juego-resultado/search';

import ActionAnular from './components/actions/anular';

//import Available from './available.vue';

export default {
    components: {
        JuegoResultadoModal,
        JuegoResultadoSearch,
        ActionAnular,
        //Available
    },
    data: function () {
        return {

            options: {},
            loading: false,
            juegoResultadoList: [],
            juegoResultadoCount: 0,

            isModalResultadoOpen: false,

            params: {
                is_anulado: false,
                fecha: this.$luxon.local().toISODate()
            }
        }
    },
    computed: {
        headers: function() {
            return [
                {
                    text: 'Juego',
                    value: 'juego',
                    align: 'start',
                    width: 'auto',
                    sortable: false,
                },
                {
                    text: 'Resultado',
                    value: 'resultado',
                    align: 'start',
                    sortable: false,
                },
                {
                    text: 'Tickets Ganadores',
                    value: 'winner_count',
                    align: 'start',
                    sortable: false,
                    width: 'auto',
                },
                {
                    text: 'Acciones',
                    value: 'actions',
                    align: 'right',
                    width: 'auto',
                    sortable: false,
                },
            ];
        }
    },
    created: function () {
    },
    methods: {
        getJuegoResultadoList: function (params = {}) {
            this.loading = true;
            if(!Object.hasOwn(params,'limit')) params = {...params, ...this.getOptions()};
            JuegoResultadoService.getList({...this.params, ...params})
                .then(({juegoResultadoList, juegoResultadoCount}) => {
                    this.juegoResultadoList = juegoResultadoList;
                    this.juegoResultadoCount = juegoResultadoCount;
                }).finally(() => {
                    this.loading = false;
                });
        },
        openResultadoModal: function () {
            this.isModalResultadoOpen = true;
        },
        resultadoAnulado: function (juegoResultado) {
            const juegoResultadoTarget = this.juegoResultadoList.find((juegoResultadoItem) => juegoResultadoItem.id == juegoResultado.id);
            if (!juegoResultadoTarget) return;
            juegoResultadoTarget.is_anulado = juegoResultado.is_anulado;
        },
        postSaved: function () {
            this.juegoResultadoCount = this.juegoResultadoCount + 1;
            this.getJuegoResultadoList();
        },
        
        getOptions: function () {
            const params = {};
            let itemsPerPage = null;
            if (this.options.itemsPerPage !== -1) itemsPerPage = this.options.itemsPerPage
            else if (this.count) itemsPerPage = this.juegoResultadoCount
            params['limit'] = itemsPerPage;
            params['offset'] = (this.options.page * itemsPerPage) - itemsPerPage;
            return params;
        },
    },
    watch: {
      options: {
        handler: function () {
            this.getJuegoResultadoList(this.getOptions());
        },
        deep: true,
      },
    },
}
</script>